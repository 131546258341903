import { isPast } from "date-fns";
import { SavePushNotificationModel } from "../http/requestModels/SavePushNotificationModel";
import notificationService from "../http/services/NotificationService";
import { NotificationPushTemplate } from "./models/NotificationPushTemplate";

class PushNotificationsSubject {
    notificationPush = [];
    selectedPage = "viewer";
    selectedType = "BATCH";
    categories = [];
    observers = [];
    newNotificationPush = new NotificationPushTemplate();
    filteredNotificationPush = [...this.notificationPush];
    pullInterval = null;
    file = null;
    fileValues = { title: '', body: '', target: '', type: '' }
    isScheduled = false;
    dateSelected = new Date();
    selectedTime = { start: new Date() };

    /**
     * Gets all the notifications and categories available in the DB. Then, updates the local variables
     */
    async updateNotifications() {
        this.notificationPush = await notificationService.getAll();
        this.categories = await notificationService.getCategories();
        this.filteredNotificationPush = [...this.notificationPush];
        this.notify();
    }

    async notifyfilteredNotificationPushChanged() {
        this.filteredNotificationPush = [...this.notificationPush];
        this.notify();
    }

    setFileValues(values) {
        this.fileValues = values
    }

    someFileValuesEmpty() {
        const { title, body, target, type } = this.fileValues

        return !(title && body && target && type)
    }

    /**
     * Updates the local type variable.
     * @param {String} type type of notification: could be batch or custom
     */
    setSelectedType(type) {
        this.selectedType = type;
        this.notify();
    }
    /**
     * Updates the local page variable
     * @param {String} page that is currently rendered: colud be viewer or create
     */
    setSelectedPage(page) {
        this.selectedPage = page;
        this.notify();
    }
    /**
     * Updates the local status varibale
     * @param {Boolean} value defines if the notiication is active or not.
     */
    updateStatus(value) {
        this.newNotificationPush.status = value;
    }
    /**
     *  Adds an observer function to the array of observers
     * @param {Function} o observer function created in the push notification viewer component
     */
    addObservers(o) {
        this.observers.push(o);
    }
    /**
     * Removes an observer function from the array of observers
     * @param {Function} o observer function created in the push notification viewer component
     */
    removeObservers(o) {
        let index = this.observers.indexOf(o);
        this.observers.splice(index, 1);
    }
    /**
     *  Runs all the observer functions in the array of observers.
     */
    notify() {
        this.observers.forEach((element) => element());
    }
    /**
     * Saves the received time to the selectedTime value
     * @param {*} time
     * @param {*} value
     */
    saveTime(time, value) {
        let obj = { ...this.selectedTime };
        obj[time] = value;
        this.selectedTime = { ...obj };
        //if (time === 'start') this.getSelectedDay().start = value
        this.notify();
    }
    /**
     * Creates a new instance on the NotificationPushTemplate and setal all the local variables to their default value.
     */
    async cleanSelectedItem() {
        this.file = null;
        this.newNotificationPush = new NotificationPushTemplate();
        this.setSelectedType(null);
        this.setSelectedPage("viewer");
    }
    /**
     * Deletes a selectes notification from the array of notifications.
     * @param {Number} id of the notification to delete
     */
    deleteSelectedItem(id) {
        let selected = [...this.notificationPush];
        let find = selected.find((elem) => elem.id === id);
        let index = selected.indexOf(find);
        selected.splice(index, 1);
        this.notificationPush = [...selected];
        this.filteredNotificationPush = [...selected];
        this.notify();
    }

    /**
     *Navigates to the creating page, and sets all the inputs with the items data.
     */
    modifyItem(item) {
        this.setSelectedPage("create");
        this.selectedType = item.type;
        this.newNotificationPush = { ...item };
    }
    /**
     * Filters the array of notificationsPush by name. If the input value is empty it sets the filterd array to equal the notificationsPush array
     * @param {Input event} e
     */
    searchHandler(e) {
        let arr = [...this.notificationPush];
        let inputText = e.target.value.toUpperCase();

        let filtered = arr.filter((elem) => {
            let name = elem.name.toUpperCase();
            return name.includes(inputText);
        });

        this.filteredNotificationPush = [...filtered];

        if (e.target.value === "") {
            this.filteredNotificationPush = [...this.notificationPush];
        }

        this.notify();
    }

    /**
     * Sorts the array of notifications, in alphabetical order by name
     */
    sortNotificationsByName() {
        let arr = [...this.notificationPush];

        arr.sort(function(a, b) {
            let first = a.name.toLowerCase();
            let second = b.name.toLowerCase();
            if (first < second) {
                return -1;
            }
            if (first > second) {
                return 1;
            }
            return 0;
        });
        this.filteredNotificationPush = [...arr];
        this.notify();
    }

    /**
     * Updates the new notification push instance, specifically the file key.
     * @param {File} file wihs a txt fromat
     */
    updateFile(file) {
        this.file = file;
        this.notify();
    }
    async saveNotificationScheduled(dateSelected, timePicked) {
        let startDay = new Date(dateSelected);
        startDay.setHours(timePicked.start.getHours());
        startDay.setMinutes(timePicked.start.getMinutes());
        startDay.setSeconds(0);

        if (
            this.newNotificationPush.category === 0 ||
            this.newNotificationPush.description === "" ||
            this.newNotificationPush.name === ""
        ) {
            alert("Quedan campos por completar");
        } else if (this.file === null) {
            alert("La notificación debe contar con un archivo (Formato CSV)");
        } else if (isPast(startDay)) {
            alert("No se pueden programar eventos en el pasado");
        } else {
            let arr = [...this.notificationPush];
            this.newNotificationPush.type = "BATCH";
            this.newNotificationPush.status = "SCHEDULED";
            this.newNotificationPush.sendingDate = startDay;
            const requestBody = new SavePushNotificationModel(
                this.newNotificationPush
            );

            this.file = await this.formatFileWithValues(this.file)

            let jsonResponse;

            try {
                jsonResponse = await notificationService.post(requestBody);
                notificationService.sendFileScheduled(
                    jsonResponse.data.id,
                    this.file,
                    startDay.toISOString()
                );

                jsonResponse &&
                    arr.unshift(new NotificationPushTemplate(jsonResponse.data));

                this.pullInterval = setInterval(() => {
                    this.checkForPendingNotifications();
                }, 10000);
            } catch (error) {
                console.log(error);
            }

            this.notificationPush = [...arr];
            this.filteredNotificationPush = [...this.notificationPush];
            this.cleanSelectedItem();
            this.selectedPage = "viewer";
            this.notify();
        }
    }
    /**
     * Checks if all the required inputs of the create notification form are filled.
     * Adds the new notification instance to the array of notifications push, and to the array of filtered notifications push.
     */
    async saveNotification() {
        if (
            !this.newNotificationPush.category ||
            !this.newNotificationPush.description ||
            !this.newNotificationPush.name
        ) {
            alert("Quedan campos por completar");
            return;
        } else if (this.file === null) {
            alert("La notificación debe contar con un archivo (Formato CSV)");
            return;
        }

        let arr = [...this.notificationPush];
        this.newNotificationPush.type = "BATCH";
        const requestBody = new SavePushNotificationModel(this.newNotificationPush);
        let jsonResponse;

        this.file = await this.formatFileWithValues(this.file)

        try {
            if (this.newNotificationPush.id) {
                const id = this.newNotificationPush.id;
                const find = arr.find((element) => element.id === id);
                const index = arr.indexOf(find);

                jsonResponse = await notificationService.put(id, requestBody);
                await notificationService.sendFile(id, this.file);
                jsonResponse &&
                    (arr[index] = new NotificationPushTemplate(jsonResponse.data));
            } else {
                jsonResponse = await notificationService.post(requestBody);
                notificationService.sendFile(jsonResponse.data.id, this.file);
                jsonResponse &&
                    arr.unshift(new NotificationPushTemplate(jsonResponse.data));

                this.pullInterval = setInterval(() => {
                    this.checkForPendingNotifications();
                }, 10000);
            }
        } catch (error) {
            console.log(error);
        }

        this.notificationPush = [...arr];
        this.filteredNotificationPush = [...this.notificationPush];
        this.cleanSelectedItem();
        this.selectedPage = "viewer";
        this.notify();
    }

    async checkForPendingNotifications() {
        let unfinished = this.notificationPush.filter(
            (element) =>
                element.status !== "Finalizada" && element.status !== "Fallida"
        );
        if (unfinished.length > 0) {
            this.notificationPush = await notificationService.getAll();
            this.filteredNotificationPush = [...this.notificationPush];
        } else {
            clearInterval(this.pullInterval);
        }
        this.notify();
    }


    async formatFileWithValues(file) {
        const columns = ['SocioId', 'DNI', 'Email']

        let content = await file.text();
        content = content.replaceAll('\r', '\n')

        const separator = content.slice(columns[0].length, columns[0].length + 1)

        if (!(/\r?\n/.test(content))) {
            let regex = new RegExp(separator, 'g');
            let count = 0
            content = content.replace(regex, (match, offset) => {
                count++
                console.log(count)
                return count % columns.length === 0 ? '\n' : match
            })
        }
        let rows = content.split(/\n/)
        rows = rows.slice(1, rows.length).map(row => row.split(separator))

        const { title, body, type, target } = this.fileValues
        let csvContent = [['Title', 'Body', 'Type', 'Target', 'SocioId', 'DNI', 'Email'], ...rows.filter(row => Boolean(row[0] || row[1] || row[2])).map(row => [title, body, type, target, row[0], row[1], row[2]])]
        csvContent = csvContent.map(row => row.map(field => {
            return field.includes(',') ? `"${field}"` : field
        }))

        const newFile = new File([csvContent.map(row => row.join(',')).join('\n')], file.name, { type: 'text/csv' })

        return newFile
    }
}
const pushNotificationSubject = new PushNotificationsSubject();
export default pushNotificationSubject;
