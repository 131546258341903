import { useEffect, useState } from "react";
import sedeSubject from "../../../subjects/SedeSubject";
import { Grid, IconButton, Typography } from "@mui/material";
import theme from "../../../css/Theme";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page, selectedRoom) => {
  sedeSubject.setSelectedPage(page)
  sedeSubject.setSelectedName(selectedRoom)
}



/**
 * Renders the array of notifications as accordions.
 * @returns
 */
export const SedesList = () => {
  const [sedes, setSede] = useState(sedeSubject.filteredSedes);
  const [stateFilter, setStateFilter] = useState('SIN_FILTRO')

  const filterPosibles = {
    SIN_FILTRO: <></>,
    FILTRO_ASCENDENTE: <ExpandMoreOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => setStateFilter('FILTRO_DESCENDENTE')}/>,
    FILTRO_DESCENDENTE: <ExpandLessOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => setStateFilter('SIN_FILTRO')}/>
  }
  /**
   * Updates the local push notification array to the filtered notifiactions array from the ṕush notification subject.
   */
  const onPushNotificationUpdate = () => {
    setSede(sedeSubject.filteredSedes);
  };
  /**
   * Subscribes the component to the subject.
   */
  useEffect(() => {
    sedeSubject.addObservers(onPushNotificationUpdate);
    return () => {
      sedeSubject.removeObservers(onPushNotificationUpdate);
    };
  }, []);


  useEffect(() => {
    console.log('CREAR FILTRO')

  }, [stateFilter])
  



  return (
    <>
      <Grid
        container
        item
        wrap="nowrap"
        direction="column"
        xs={12}
        sx={{
          overflowY: "auto",
          background: theme.palette.primary.main,
          height: "100%",
        }}
      >
        <Grid
          justifyContent={'flex-start'}
          marginBottom={'10px'}
          item
          container
          direction="row"
        >
          <Grid
            justifyContent={'flex-start'}
            item
            container
            direction="row"
            xs={6}
          >
            <Typography
              color={theme.palette.black.main}
              noWrap
              sx={{ fontWeight: "bolder" }}
              fontSize={'1.2rem'}
            >
              Nombre
            </Typography>
          </Grid>
          <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            //TODO: Cambiar el valor de xs a 2 cuando se descomente todo lo del eliminar
            xs={3}
          >
            <Grid
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              color={theme.palette.darkGray.main}
              gap={'10px'}
            >
              <Typography
                color={theme.palette.black.main}
                noWrap
                sx={{ 
                  fontWeight: "bolder",
                  cursor: stateFilter === 'SIN_FILTRO' ? 'pointer' : 'default'
                }}
                fontSize={'1.2rem'}
                onClick={() => {
                  if (stateFilter === 'SIN_FILTRO') {
                    setStateFilter('FILTRO_ASCENDENTE');
                  }
                }}              
                >
                Estado
              </Typography>
              {filterPosibles[stateFilter]}
            </Grid>
          </Grid>
          <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            // TODO: Cambiar el valor de xs a 2 cuando se descomente todo lo del eliminar
            xs={3}
          >
            <Typography
              color={theme.palette.black.main}
              noWrap
              sx={{ fontWeight: "bolder" }}
              fontSize={'1.2rem'}
            >
              Editar
            </Typography>
          </Grid>
          {/* //TODO: Descomentar cuando se agregue la funcionalidad de eliminar */}
          {/* <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            xs={2}
          >
            <Typography
              color={theme.palette.black.main}
              noWrap
              sx={{ fontWeight: "bolder" }}
              fontSize={'1.2rem'}
            >
              Eliminar
            </Typography>
          </Grid> */}
        </Grid>
        {sedes.map((sede, i) => {
            const backgroundColor = i % 2 === 0 ? '#e6e7e6' : 'white';
            return <SedeSummary key={i} id={`${sede.nombre}-${i}`} value={sede} backgroundColor={backgroundColor}/>;
        })}
      </Grid>
    </>
  );
};
const SedeSummary = ({ value, backgroundColor }) => {


  const [loading, setloading] = useState(false);

  console.log("SedeSummary")
  const changeActivateState = async () => {
    setloading(true);
    const newState = value.estatus === 1 ? 0 : 1;
    const newSalon = sedeSubject.filteredSedes.find(sede => sede.idClub === value.idClub);
    newSalon.estatus = newState;
    try {
      await sedeSubject.putSede(newSalon);
      setloading(false);
    } catch (error) {
        alert("Hubo un error al actualizar el estado de la sede. Por favor vuelva a intentar");
        setloading(false);
    }
  };

  return (
    <>
      <Grid direction="row">
        <Grid
          item
          container
          direction="row"
          minHeight={'2.5rem'}
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
          sx={{backgroundColor: backgroundColor}}
        >
          <Grid
            justifyContent={'flex-start'}
            item
            container
            direction="row"
            xs={6}
          >
            <Typography
              color={theme.palette.darkGray.main}
              noWrap
              sx={{ fontWeight: "bolder", marginLeft: '1rem' }}
            >
              {value.nombre}
            </Typography>
          </Grid>
          <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            //TODO: Cambiar el valor de xs a 2 cuando se descomente todo lo del eliminar
            xs={3}
            sx={{borderLeft: 'solid black 1px', borderRight: 'solid black 1px'}}
          >
            {
              loading 
              ? <Typography color={theme.palette.darkGray.main} sx={{ fontWeight: "bold"}}> Cargando...</Typography>
              : 
                <Typography
                  color={value.estatus ? 'black' : theme.palette.darkOrange.main }
                  noWrap
                  sx={{ fontWeight: "bold", cursor: 'pointer' }}
                  onClick={changeActivateState}
                >
                  {value.estatus ? "Activo" : "Inactivo"}
                </Typography>
            }
          </Grid>
          <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            //TODO: Cambiar el valor de xs a 2 cuando se descomente todo lo del eliminar
            xs={3}
            //TODO: Descomentar cuando se agregue la funcionalidad de eliminar
            // sx={{borderRight: 'solid black 1px'}}

          >
            <IconButton onClick={() => navigateOnControls('edit', value)} sx={{margin: '-7px'}}  disabled={sedeSubject.selectedPage === "edit"}>
              <EditOutlinedIcon  />
            </IconButton>
          </Grid>
          {/* //TODO: Descomentar cuando se agregue la funcionalidad de eliminar */}
          {/* <Grid
            justifyContent={'center'}
            item
            container
            direction="row"
            xs={2}
          >
            <IconButton>
              <DeleteOutlineOutlinedIcon  />
            </IconButton>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};
